"use client"
import {membersData} from "../../../data/index"
import { MemberCard } from "./MemberCard"
import gsap from "gsap"
import { useEffect,useRef } from "react"
import { ScrollTrigger } from "gsap/all"

export const SeeHomeMembers = ()=>{
  const gsapRef = useRef<HTMLDivElement>(null);

  // useEffect(()=>{
  //   gsap.registerPlugin(ScrollTrigger)
  //   if(gsapRef.current){

  //     let ctx = gsap.context(() => {
  //       const tl = gsap.timeline({
  //         scrollTrigger:{
  //           trigger:gsapRef.current,
  //           start:()=>"center-=10% bottom",
  //           end:()=>"center+=3% center",
  //           // markers:true,
  //           // pin:true,
  //           scrub:.1,
  //         },
  //         ease: "none",
  //       });
  //       tl.fromTo(".members-container .sides",{
  //         x:(i)=> i===0? "-2.8rem" : "2.8rem",
  //         scale:()=>.86,
  //       },{
  //         x:(i)=> i===0? "0.7rem" : "-0.7rem",
  //         scale:()=> 1,
  //       })
  //     },gsapRef.current)

  //     return ()=>{
  //       ctx.revert();
  //     }
  //   }

  // },[])

  return(
    <>
    <div className='members-container overflow-container flex flex-col gap-[.5rem] text-center bg-white-100 text-black pt-[2.5rem] mobile:pb-[3.5rem] mobile:w-[100vw] mobile:overflow-hidden' ref={gsapRef}>      <div>
        <h3 className='text-regular75 w-[20ch] mx-auto mobile:text-[2.1rem] mobile:w-full mobile:px-[1.2rem] mobile:leading-[1.5]'>
        See who else is joining
        </h3>
      </div>
      <div>
        <div  className='pt-[.94rem] pb-[4rem] flex flex-row justify-center members-cards mobile:pt-[3rem]' >
         { membersData.map((member,i)=>{
          const {src, title, name, front,flag}=member

           return(
             <MemberCard key={i} src={src} title={title} name={name} front={front} flag={flag}/>
           )
          })
          }
        </div>
      </div>
    </div>
    </>
  )
}