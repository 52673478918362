import { useEffect,useState, useRef } from "react";
import gsap from "gsap"

export const TesTrigger = ()=>{

  // const [activeSlide, setActiveSlide] = useState<HTMLElement>()
  const [color, setColor] = useState<string>()

  useEffect(()=>{
    let ctx = gsap.context(() => {
    // const tesContainer:HTMLElement[]=gsap.utils.toArray(".tes-scroller-wrapper")
    const tesContent:HTMLElement[]=gsap.utils.toArray(".tes-scroller")
    const tesButtons:HTMLElement[]=gsap.utils.toArray(".tes-button")
    const enter = ()=>{

    }
    tesContent.forEach((contentDiv:HTMLElement, index:number )=>{

      const TesDisplay = gsap.timeline({
        scrollTrigger:{
          trigger:contentDiv,
          start:()=>"top center+=10%",
          end:()=>"bottom center-=10%",
          // markers:true,
          onEnter: ()=>{
            // setColor(()=>contentDiv.dataset.name)
            if(contentDiv.dataset.name === tesButtons[index].dataset.name){
              gsap.to(tesButtons[index],{
                opacity: 1,
                // fontWeight:500,
                duration:.4,
              })
              gsap.to(tesButtons[index],{
                // opacity: 1,
                fontWeight:500,
                duration:0,
              })
              
              const otherButtons:HTMLElement[]= tesButtons.filter((button)=> button.dataset.name !== contentDiv.dataset.name)
              gsap.to((otherButtons),{
                    opacity: .5,
                    duration:.4,
                  })
                  gsap.to((otherButtons),{
                    fontWeight: 400,
                    duration:0,
                  })
                  
            }
          //  console.log("entering", self.direction)
          },
          // onLeave:()=> LeaveAnimation(index),
          onEnterBack:()=>{
            // setColor(()=>contentDiv.dataset.name)
            // console.log("enterback")
            if(contentDiv.dataset.name === tesButtons[index].dataset.name){
              gsap.to(tesButtons[index],{
                opacity: 1,
                // fontWeight:500,
                duration:.4,
              })
              gsap.to(tesButtons[index],{
                // opacity: 1,
                fontWeight:500,
                duration:0,
              })

              const otherButtons:HTMLElement[]= tesButtons.filter((button)=> button.dataset.name !== contentDiv.dataset.name)
              gsap.to((otherButtons),{
                    opacity: .5,
                    duration:.4,
                    // fontWeight:400,
                  })
                  gsap.to((otherButtons),{
                    // opacity: .5,
                    duration:0,
                    fontWeight:400,
                  })
            }
          },
          scrub:true,
        },
        // onComplete:()=>{ 0 !== index? gsap.to(imgsMask[index],{display:"none"}) : null},
        ease: "none",
      })
      // .to()
     })
     const tesContainer:HTMLElement[]=gsap.utils.toArray(".tes-scroller-wrapper")
     const tesScrollContent:HTMLElement[]=gsap.utils.toArray(".tes-scroll-content")

     const TesScroll = gsap.timeline({
      scrollTrigger:{
        trigger:tesContainer,
        start:()=>"top top",
        end:()=>"bottom bottom",
        // markers:true,
        // onLeave:()=> LeaveAnimation(index),
        // onEnterBack:()=>gsap.to(imgsMask[index],{opacity:1, duration:0,display:"flex"}),
        scrub:true,
      },
      // onComplete:()=>{ 0 !== index? gsap.to(imgsMask[index],{display:"none"}) : null},
      ease: "none",
    })
    .to(tesScrollContent,{
      yPercent:()=>-(100*(tesContent.length-1))/tesContent.length,
      ease: "none",
    })

    }, ".testimonials");
    return ()=>{
      ctx.revert()
    }

  },[color])
  

  return (null)
}